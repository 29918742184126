import { createAjv, JsonFormsCore } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import type Ajv from 'ajv';
import { uniqueId } from 'lodash';
import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  closeAllModals,
  Horizontal,
  Loader,
  notifications,
  Vertical,
} from '../../../design-system/v2';
import { StorageType } from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import {
  useCreateConnectorMutation,
  useGetAirbyteConnectorJsonQuery,
} from '../../../queries/connectors';
import { renderers } from '../../common/form/renderers';

interface AirbyteConnectorModalProps {
  connectorType: StorageType;
}

export const AirbyteConnectorModal = ({ connectorType }: AirbyteConnectorModalProps) => {
  const [values, setValues] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isLoading,
    isError,
    data: connectorConfig,
  } = useGetAirbyteConnectorJsonQuery(connectorType);
  const { mutateAsync: createConnector } = useCreateConnectorMutation();

  if (isLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box>
        <Alert>Error fetching configuration for connector</Alert>
      </Box>
    );
  }

  const handleFormChange = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    setValues(data);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await createConnector({
        connectorType,
        credData: values as any,
        name: uniqueId('connector '),
        connectorMetadata: {},
      });
    } catch (e) {
      logger.error('Create connection failed');
      notifications.error('Failed to create connection');
      return;
    } finally {
      setIsSubmitting(false);
    }

    notifications.success('Connection created');
    closeAllModals();
  };

  const handleDefaultsAjv = createAjv({ useDefaults: true }) as unknown as Ajv;
  const parameters = (connectorConfig as any)?.parameters;

  return (
    <Vertical h="100%" spacing={0} justify="space-between">
      <JsonForms
        schema={parameters.schema}
        data={values}
        renderers={renderers}
        onChange={handleFormChange}
        readonly={isSubmitting}
        validationMode="ValidateAndShow"
        ajv={handleDefaultsAjv}
      />
      <Horizontal pt="lg">
        <Button
          w="168px"
          ml="auto"
          variant="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Horizontal>
    </Vertical>
  );
};
