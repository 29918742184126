import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  AnswerTypes,
  BaseAPIFilter,
  ChatterRequestModel,
  DataSourceConversationModel,
  DataSourceInitConnectionResponseModel,
  DataSourceThreadModel,
  ListResourceRecordResponse,
  Operator,
  QuestionTypes,
  ResourceModel,
  StorageType,
} from '../generated/api';
import { chatterApi, connectorsApi } from '../lib/api';
import { API_PAGE_SIZE } from './constants';
import { getFileUploadConfig } from './data-upload';
import { getNextPageParamHandler } from './util';

const LIST_THREADS_PAGE_SIZE = 15;
const LIST_CONVERSATIONS_PAGE_SIZE = 5;

export const dataSourcesKeys = {
  all: ['data-sources'] as const,
  lists: (workspaceId: string) => [...dataSourcesKeys.all, 'list', workspaceId] as const,
  listV2: (
    workspaceId: string,
    filters?: BaseAPIFilter[],
    page = 1,
    limit = API_PAGE_SIZE,
    isDataSink = false,
  ) => [...dataSourcesKeys.lists(workspaceId), 'v2', { filters, page, limit, isDataSink }] as const,
  listV3: (
    workspaceId: string,
    filters: BaseAPIFilter[],
    start: number,
    limit: number,
    searchQuery: string,
  ) =>
    [...dataSourcesKeys.lists(workspaceId), 'v3', { filters, start, limit }, searchQuery] as const,
  detail: (workspaceId: string, resourceId: string) =>
    [...dataSourcesKeys.all, 'detail', workspaceId, resourceId] as const,
  preview: (workspaceId: string, resourceId: string) =>
    [...dataSourcesKeys.all, 'preview', workspaceId, resourceId] as const,
};

export const chatWithDataSourceKeys = {
  all: ['chat-with-data-source'] as const,
  initChatConnection: (workspaceId: string, resourceId: string) =>
    [...chatWithDataSourceKeys.all, 'init-chat-connection', workspaceId, resourceId] as const,
  threads: (workspaceId: string, resourceId: string) =>
    [...chatWithDataSourceKeys.all, 'threads', workspaceId, resourceId] as const,
  threadConversations: (workspaceId: string, resourceId: string, threadId: string) =>
    [...chatWithDataSourceKeys.all, 'conversations', workspaceId, resourceId, threadId] as const,
};

export const useInvalidateDataSourcesList = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(dataSourcesKeys.lists(workspaceId));
};

export const useGetDataSourcesListQueryV2 = (
  filters?: BaseAPIFilter[],
  page = 1,
  limit = 200,
  isDataSink?: boolean,
) => {
  const { workspaceId } = useAppMetadata();

  const connectorType = filters?.find(f => f.field === 'connectorType')?.value;

  return useQuery<
    AxiosResponse<ListResourceRecordResponse>,
    AxiosError,
    ListResourceRecordResponse
  >(
    dataSourcesKeys.listV2(workspaceId, filters, page, limit, isDataSink),
    () =>
      connectorsApi.listConnectorResourcesV2(
        workspaceId,
        (page - 1) * limit,
        page * limit,
        isDataSink,
        connectorType ? [connectorType] : undefined,
      ),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
      keepPreviousData: true,
    },
  );
};

export const useGetConnectorResourcesListQueryV3 = (
  connectorTypes?: StorageType[],
  isDataSink?: boolean,
  page = 1,
  limit = 100,
  searchQuery = '',
) => {
  const { workspaceId } = useAppMetadata();

  const filters: BaseAPIFilter[] = [];
  if (connectorTypes) {
    filters.push({
      field: 'connectorType',
      operator: Operator.In,
      value: connectorTypes,
    });
  }

  if (isDataSink !== undefined) {
    filters.push({
      field: 'isDataSink',
      operator: Operator.Eq,
      value: isDataSink,
    });
  }

  const start = (page - 1) * limit;

  return useQuery<
    AxiosResponse<ListResourceRecordResponse>,
    AxiosError,
    ListResourceRecordResponse
  >(
    dataSourcesKeys.listV3(workspaceId, filters, start, limit, searchQuery),
    () =>
      connectorsApi.listConnectorResourcesV3(workspaceId, { filters, start, limit }, searchQuery),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
      keepPreviousData: true,
    },
  );
};

export const useDataSourceDetailsQuery = (resourceId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<ResourceModel>, AxiosError, ResourceModel>(
    dataSourcesKeys.detail(workspaceId, resourceId),
    () => connectorsApi.getResourceDetailsV1(workspaceId, resourceId),
    {
      enabled: Boolean(workspaceId && resourceId),
      select: res => res.data,
    },
  );
};

export const useGetDataSourcePreviewQuery = (resourceId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    dataSourcesKeys.preview(workspaceId, resourceId),
    () => connectorsApi.getResourcePreviewV1(workspaceId, resourceId),
    {
      enabled: Boolean(workspaceId && resourceId),
      select: res => res.data.response,
    },
  );
};

export const useGetPresignedUrlMutation = () => {
  const { workspaceId } = useAppMetadata();

  return useMutation((req: { file: File }) =>
    connectorsApi.addUploadResourceV1(workspaceId, {
      fileInfo: getFileUploadConfig(req.file),
    }),
  );
};

export const useAskDataSourceMutation = (resourceId: string, threadId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (chatterRequestModel: ChatterRequestModel) =>
      chatterApi.askMarkovForDataSourceV1(workspaceId, resourceId, chatterRequestModel),
    {
      onSuccess: () => {
        const conversationsKey = chatWithDataSourceKeys.threadConversations(
          workspaceId,
          resourceId,
          threadId,
        );
        queryClient.invalidateQueries(conversationsKey);
      },
    },
  );
};

// Helper to simulate adding a new thread
export const useAddLocalThread = (resourceId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  const addThread = (newThread: DataSourceThreadModel) => {
    // Insert dummy thread into query cache for threads list
    const threadsKey = chatWithDataSourceKeys.threads(workspaceId, resourceId);
    const queryData = queryClient.getQueryData(threadsKey) as
      | AxiosResponse<DataSourceThreadModel[]>
      | undefined;

    const nextQueryData = queryData
      ? {
          ...queryData,
          data: [...(queryData.data ?? []), newThread],
        }
      : { data: [newThread] };

    queryClient.setQueryData(threadsKey, nextQueryData);
  };

  return addThread;
};

// Helper to simulate adding a new chat message
export const useAddLocalThreadQuestion = (resourceId: string, threadId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  const addQuestion = (query: string, questionType: QuestionTypes) => {
    // Insert dummy question into query cache for thread conversations
    const newItem: DataSourceConversationModel & { isLoading?: boolean } = {
      conversationId: uuid(),
      threadId,
      question: query,
      questionType: questionType,
      answer: '',
      answerType: AnswerTypes.Text,
      isLoading: true,
      createDate: new Date().toDateString(),
    };

    const conversationsKey = chatWithDataSourceKeys.threadConversations(
      workspaceId,
      resourceId,
      threadId,
    );
    const conversationsData = queryClient.getQueryData(conversationsKey) as
      | InfiniteData<DataSourceConversationModel[]>
      | undefined;

    const nextConversationsData = {
      ...(conversationsData ?? {}),
      pages: [{ data: { response: [newItem] } }, ...(conversationsData?.pages ?? [])],
    };

    queryClient.setQueryData(conversationsKey, nextConversationsData);
  };

  return addQuestion;
};

export const useChatWithResourceThreadsListQuery = (resourceId: string) => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery(
    chatWithDataSourceKeys.threads(workspaceId, resourceId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * LIST_THREADS_PAGE_SIZE,
        end = start + LIST_THREADS_PAGE_SIZE;
      return chatterApi.listDataSourceThreadsV2(workspaceId, resourceId, start, end, lastTimestamp);
    },
    {
      getNextPageParam: getNextPageParamHandler(LIST_THREADS_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data),
      }),
      enabled: Boolean(workspaceId),
    },
  );
};

export const useChatWithResourceThreadConversationsQuery = (
  resourceId: string,
  threadId: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery(
    chatWithDataSourceKeys.threadConversations(workspaceId, resourceId, threadId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * LIST_CONVERSATIONS_PAGE_SIZE,
        end = start + LIST_CONVERSATIONS_PAGE_SIZE;
      return chatterApi.listChatWithResourceConversationsV2(
        workspaceId,
        threadId,
        start,
        end,
        lastTimestamp,
      );
    },
    {
      getNextPageParam: getNextPageParamHandler(LIST_CONVERSATIONS_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
      enabled: Boolean(workspaceId && resourceId && threadId),
    },
  );
};

export const useInitDataSourceConnectionSetupQuery = (resourceId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<DataSourceInitConnectionResponseModel>,
    AxiosError,
    DataSourceInitConnectionResponseModel
  >(
    chatWithDataSourceKeys.initChatConnection(workspaceId, resourceId),
    () => chatterApi.initDataSourceConnectionSetupV1(workspaceId, resourceId),
    {
      enabled: Boolean(workspaceId && resourceId),
      select: res => res.data,
    },
  );
};
