import { IconFileText, IconTrashX } from '@tabler/icons-react';
import { first } from 'lodash';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Dropzone,
  DropzoneProps,
  FileWithPath,
  Horizontal,
  MIME_TYPES,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../../design-system/v2';

export interface Accept {
  [key: string]: string[];
}

const defaultDropzoneAccept = {
  [MIME_TYPES.csv]: ['.csv'],
  'text/plain': ['.txt'],
  'text/tab-separated-values': ['.tsv'],
};

interface FileUploadInputProps {
  supportedFilesTypes?: string[];
  file?: FileWithPath;
  dropzoneAccept?: DropzoneProps['accept'];
  maximumFileSize?: string;
  onFileDrop: (file: FileWithPath) => void;
  onFileDelete: () => void;
}

export const FileUploadInput = ({
  file,
  supportedFilesTypes,
  dropzoneAccept = defaultDropzoneAccept,
  maximumFileSize,
  onFileDrop,
  onFileDelete,
}: FileUploadInputProps) => {
  const theme = useMarkovTheme();

  const handleFileOnDrop = (files: FileWithPath[]) => {
    const file = first(files);

    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileDelete = () => {
    onFileDelete();
  };

  return (
    <Box pos="relative">
      <Dropzone bg="gray.0" accept={dropzoneAccept} onDrop={handleFileOnDrop} py={32}>
        {file ? (
          <Box p="md">
            <Horizontal spacing="sm">
              <IconFileText size={20} color={theme.colors.gray[7]} />
              <Text variant="subTitle03" color="gray.8" underline>
                {file.name}
              </Text>
            </Horizontal>
          </Box>
        ) : (
          <Center>
            <Vertical align="center" spacing="sm">
              {supportedFilesTypes && (
                <Text variant="bodyShort03" color="gray.6">
                  Supported file formats: {supportedFilesTypes.join(', ')}
                </Text>
              )}
              {maximumFileSize && (
                <Text variant="bodyShort03" color="gray.6">
                  Maximum file size: {maximumFileSize}
                </Text>
              )}
              <Vertical spacing={0} align="center">
                <Text variant="subTitle04" color="gray.7">
                  Drag and drop to upload your file
                </Text>
                <Text variant="subTitle04" color="gray.7">
                  or
                </Text>
              </Vertical>
              <Button variant="outline">Choose a file</Button>
            </Vertical>
          </Center>
        )}
      </Dropzone>

      {file && (
        <Box pos="absolute" right={24} top={44}>
          <ActionIcon onClick={handleFileDelete} aria-label="Delete file">
            <IconTrashX />
          </ActionIcon>
        </Box>
      )}
    </Box>
  );
};
