import debounce from 'lodash/debounce';
import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Connection,
  EdgeChange,
  EdgeMouseHandler,
  NodeChange,
  addEdge,
  updateEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Mode } from '../../components/workflows/create/utils';
import {
  isInvalidConnection,
  transformEdgeToWorkflowEdge,
  transformWorkflowEdgeToEdge,
} from '../../components/workflows/edges/util';
import {
  EdgeData,
  NodeData,
  WorkflowEdge,
  WorkflowNode,
  transformNodeToWorkflowNode,
  transformWorkflowNodeToNode,
} from '../../components/workflows/nodes/utils';
import { Edge, Node, OperatorModel, UpsertDAGRequest } from '../../generated/api';
import { useSaveWorkflowGraphMutation } from '../../queries/workflows/builder';

interface CreateWorkflowContext {
  workflowId: string;

  dagId: string;
  setDagId: (dagId: string) => void;

  nodes: WorkflowNode[];
  setNodes: React.Dispatch<SetStateAction<WorkflowNode[]>>;
  onNodesChange: (changes: NodeChange[]) => void;

  edges: WorkflowEdge[];
  setEdges: React.Dispatch<SetStateAction<WorkflowEdge[]>>;
  onEdgesChange: (changes: EdgeChange[]) => void;

  mode: Mode;
  setMode: (mode: CreateWorkflowContext['mode']) => void;

  onNodeDelete: (id: string) => void;

  onEdgeDelete: (edgeId: string) => void;
  onEdgeConnect: (params: Connection) => void;
  onEdgeUpdateStart: () => void;
  onEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  onEdgeUpdateEnd: (event: MouseEvent | TouchEvent, edge: Edge) => void;
  onEdgeMouseEnter: EdgeMouseHandler;
  onEdgeMouseLeave: EdgeMouseHandler;

  saveWorkflowDAG: (nodes: WorkflowNode[], edges: WorkflowEdge[]) => void;
  isSaving: boolean;
}

const CreateWorkflowContext = createContext<CreateWorkflowContext | undefined>(undefined);

export const useCreateWorkflow = () => {
  const context = useContext(CreateWorkflowContext);

  if (context === undefined) {
    throw new Error('useCreateWorkflow must be used within a CreateWorkflowProvider');
  }

  return context;
};

interface CreateWorkflowProviderProps {
  workflowId: string;
  workflowDAG: UpsertDAGRequest;
  operatorsList: OperatorModel[];
}

export const CreateWorkflowProvider = ({
  children,
  workflowId,
  workflowDAG,
  operatorsList,
}: PropsWithChildren<CreateWorkflowProviderProps>) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>(
    workflowDAG.nodes.map(node => transformNodeToWorkflowNode(node, operatorsList)),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeData>(
    workflowDAG.edges.map(edge => transformEdgeToWorkflowEdge(edge)),
  );
  const [mode, setMode] = useState<Mode>(Mode.Build);
  const [dagId, setDagId] = useState('');
  const edgeUpdateSuccessful = useRef(true);

  const { mutateAsync: saveWorkflow, isLoading: isSaving } =
    useSaveWorkflowGraphMutation(workflowId);

  const saveWorkflowDAG = (nodes: WorkflowNode[], edges: WorkflowEdge[]) => {
    const newNodes = nodes.map<Node>(node => transformWorkflowNodeToNode(node));
    const newEdges = edges.map<Edge>(edge => transformWorkflowEdgeToEdge(edge));
    const req: UpsertDAGRequest = {
      nodes: newNodes,
      edges: newEdges,
    };

    saveWorkflow(req).then(data => {
      setDagId(data.data.dagId);
    });
  };

  const saveWorkflowDAGDebounced = useCallback(debounce(saveWorkflowDAG, 500), []);

  const onNodeDelete = (id: string) => {
    const newNodes = nodes.filter(node => node.id !== id);
    const newEdges = edges.filter(edge => edge.source !== id && edge.target !== id);

    setNodes(newNodes);
    setEdges(newEdges);

    saveWorkflowDAGDebounced(newNodes, newEdges);
  };

  const onEdgeDelete = (edgeId: string) => {
    const newEdges = edges.filter(edge => edge.id !== edgeId);
    setEdges(newEdges);

    saveWorkflowDAGDebounced(nodes, newEdges);
  };

  const onEdgeConnect = (connection: Connection) => {
    if (isInvalidConnection(connection, edges)) return;
    const edge = { ...connection, type: 'custom-edge' };
    const newEdges = addEdge(edge, edges);
    setEdges(newEdges);

    saveWorkflowDAGDebounced(nodes, newEdges);
  };

  const onEdgeUpdateStart = () => {
    edgeUpdateSuccessful.current = false;
  };

  const onEdgeUpdate = (oldEdge: Edge, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;

    const newEdges = updateEdge(oldEdge, newConnection, edges);
    setEdges(newEdges);

    saveWorkflowDAGDebounced(nodes, newEdges);
  };

  const onEdgeUpdateEnd = (event: MouseEvent | TouchEvent, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      const newEdges = edges.filter(e => e.id !== edge.id);
      setEdges(newEdges);

      saveWorkflowDAGDebounced(nodes, newEdges);
    }

    edgeUpdateSuccessful.current = true;
  };

  /**
   * Updates the `data` property of an edge with a matching `edgeId` in a list of edges.
   *
   * @param edges - The array of edges in which to update the data.
   * @param edgeId - The unique identifier of the edge to update.
   * @param data - The new data to be assigned to the matching edge.
   * @returns A new array of edges with the updated edge data.
   */
  const updateEdgeDataById = (edges: WorkflowEdge[], edgeId: string, data: EdgeData) =>
    edges.map(edge => {
      if (edge.id === edgeId) return { ...edge, data };
      return edge;
    });

  const onEdgeMouseEnter: EdgeMouseHandler = (_, edge: WorkflowEdge) => {
    const edgeId = edge.id;
    setEdges(edges => updateEdgeDataById(edges, edgeId, { isHovered: true }));
  };

  const onEdgeMouseLeave: EdgeMouseHandler = (_, edge: WorkflowEdge) => {
    const edgeId = edge.id;
    setEdges(edges => updateEdgeDataById(edges, edgeId, { isHovered: false }));
  };

  const value = useMemo(
    () => ({
      workflowId,

      dagId,
      setDagId,

      nodes,
      setNodes,
      onNodesChange: (changes: NodeChange[]) => {
        onNodesChange(changes);
        saveWorkflowDAGDebounced(nodes, edges);
      },

      edges,
      setEdges,
      onEdgesChange: (changes: EdgeChange[]) => {
        onEdgesChange(changes);
        saveWorkflowDAGDebounced(nodes, edges);
      },

      mode,
      setMode,

      onNodeDelete,

      onEdgeDelete,
      onEdgeConnect,
      onEdgeUpdateStart,
      onEdgeUpdate,
      onEdgeUpdateEnd,
      onEdgeMouseEnter,
      onEdgeMouseLeave,

      saveWorkflowDAG,
      isSaving,
    }),
    [
      workflowId,
      dagId,
      nodes,
      edges,
      mode,
      onNodesChange,
      onEdgesChange,
      saveWorkflowDAG,
      isSaving,
    ],
  );

  return <CreateWorkflowContext.Provider value={value}>{children}</CreateWorkflowContext.Provider>;
};
