import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  CreateScheduledWorkflowRequest,
  CreateWorkflowDebugRunRequest,
  GetWorkflowRunStatusDetailsResponse,
  UpdateScheduledWorkflowRequest,
  WorkflowRunStatus,
  WorkflowRunStatusDetailsResponse,
  WorkflowStatus,
} from '../../generated/api';

import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { workflowApi } from '../../lib/api';
import { workflowsQueryKeys } from './list/list';
import { operatorKeys } from './operators';

export const debugRunKeys = {
  all: ['workflow-debug-'],
  initDebug: (workspaceId: string, workflowId: string) => [
    ...debugRunKeys.all,
    workspaceId,
    workflowId,
  ],
  debugRunStatus: (workspaceId: string, workflowId: string, runId?: string) => [
    ...debugRunKeys.all,
    workspaceId,
    workflowId,
    runId,
  ],
};

export const useInitDebugMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    debugRunKeys.initDebug(workspaceId, workflowId),
    (req: CreateWorkflowDebugRunRequest) =>
      workflowApi.createWorkflowDebugRunV1(workspaceId, workflowId, req),
    {
      onMutate: async newDebugRun => {
        const key = debugRunKeys.debugRunStatus(
          workspaceId,
          workflowId,
          `${workspaceId}-${workflowId}-debug-run`,
        );
        await queryClient.cancelQueries(key);

        const previousData = queryClient.getQueryData<WorkflowRunStatusDetailsResponse>(key);

        if (previousData) {
          const updatedStatus = {
            ...previousData,
            ...newDebugRun,
            wflRunStatus: WorkflowRunStatus.Created,
          };

          queryClient.setQueryData(key, updatedStatus);
        }

        return { previousData };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(debugRunKeys.all);
        queryClient.invalidateQueries(operatorKeys.all);
      },
      onError: (
        error,
        variables,
        context: { previousData?: WorkflowRunStatusDetailsResponse } | undefined,
      ) => {
        if (context?.previousData) {
          queryClient.setQueryData(
            debugRunKeys.debugRunStatus(
              workspaceId,
              workflowId,
              `${workspaceId}-${workflowId}-debug-run`,
            ),
            context.previousData,
          );
        }
      },
    },
  );
};

export const usePublishWorkflowMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    ({ execute, workflowName }: { execute: boolean; workflowName: string }) =>
      workflowApi.publishWorkflowV1(workspaceId, workflowId, execute, workflowName),
    {
      onSuccess: () => queryClient.invalidateQueries(workflowsQueryKeys.all),
    },
  );
};

export const useDebugRunStatusQuery = (workflowId: string, runId = '') => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetWorkflowRunStatusDetailsResponse>,
    AxiosError,
    WorkflowRunStatusDetailsResponse
  >(
    debugRunKeys.debugRunStatus(workspaceId, workflowId, runId),
    () => workflowApi.getWorkflowRunStatusV1(workspaceId, workflowId, runId),
    {
      enabled: !!workspaceId && !!workflowId && !!runId,
      refetchInterval: data =>
        data?.wflRunStatus === WorkflowRunStatus.Terminated ||
        data?.wflRunStatus === WorkflowRunStatus.Success ||
        data?.wflRunStatus === WorkflowRunStatus.ExecutionFailed
          ? false
          : 10 * 1000,
      select: data => data.data.response,
    },
  );
};

export const useDownloadWorkflowOutputMutation = (workflowId: string, workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation(() =>
    workflowApi.downloadWorkflowOutputV1(workspaceId, workflowId, workflowRunId),
  );
};

export const usePublishScheduledWorkflowMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CreateScheduledWorkflowRequest) =>
      workflowApi.createScheduledWorkflowV1(workspaceId, workflowId, req),
    {
      onSuccess: () => queryClient.invalidateQueries(workflowsQueryKeys.all),
    },
  );
};

export const useUpdateScheduledWorkflowMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpdateScheduledWorkflowRequest) =>
      workflowApi.updateScheduledWorkflowV1(workspaceId, workflowId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.detail(workspaceId, workflowId),
        });
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.listAllRuns(workspaceId, workflowId),
        });
      },
    },
  );
};

export const useUpdateScheduledWorkflowStateMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (status: WorkflowStatus) =>
      workflowApi.updateScheduledWorkflowStateV1(workspaceId, workflowId, status),
    {
      onSuccess: () => queryClient.invalidateQueries(workflowsQueryKeys.all),
    },
  );
};

export const useDownloadWorkflowNodeOutputMutation = (
  workflowId: string,
  runId: string,
  nodeId: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useMutation(() =>
    workflowApi.downloadWorkflowNodeOutputV1(workspaceId, workflowId, runId, nodeId),
  );
};

export const useEmailTriggerPublishMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => workflowApi.publishTriggerWorkflowV1(workspaceId, workflowId), {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: workflowsQueryKeys.all,
      }),
  });
};
